<template>
    <div id="page-content">
        <div class="container consultations">
            <div class="row px-1 py-1 py-lg-4 px-lg-4 consultations__general">
                <div class="alert alert-info alert-dismissible fade show" role="alert">
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 class="alert-heading">Informations générales</h4>
                    <p>Toutes les consultations nécessitent par obligation légale une ordonnance médicale faite par le
                        médecin généraliste ou spécialiste (ophtalmologiste, pédiatre, neuro-pédiatre, ORL,
                        neurologue...).
                    </p>
                    <hr>
                    <p class="mb-0">Pour chaque examen orthoptique, un compte-rendu est transmis au prescripteur.</p>
                </div>
            </div>
            <div class="row consultations__bilan d-flex align-items-center">
                <div class="col-12 col-lg-8 p-1 p-lg-2 consultations__text text-white">
                    <div class="m-1 m-lg-2 p-2 rounded consultations__text__content">
                        <h2>Le bilan Orthoptique</h2>
                        <div class="ml-3 ml-lg-5 consultations__text__content__paragraph">
                            <h3 class="h4">Bébé et petite enfance</h3>
                            <p><em>30 à 40 minutes, à partir de 6 mois jusqu'à 4-5 ans environ</em><br>
                                Détection de déséquilibres réfractifs et binoculaires. Cet examen permet, quand c'est
                                nécessaire, d'alerter l'ophtalmo-pédiatre et
                                d'accélérer la prise en charge.
                            </p>
                        </div>
                        <div class="ml-3 ml-lg-5 consultations__text__content__paragraph">
                            <h3 class="h4">Chez l'adulte</h3>
                            <p><em>40 minutes, à partir de 6 ans</em><br>
                                Détermination de causes d'inconfort visuel, oculaire, postural et fonctionnel.
                            </p>
                        </div>
                        <div class="ml-3 ml-lg-5 consultations__text__content__paragraph">
                            <h3 class="h4">Neuro-visuel</h3>
                            <p><em>60 minutes ou plus en fonction des capacités attentionnelles</em><br>
                                Destiné aux enfants ayant des troubles d'apprentissage. Il contient un bilan orthoptique
                                classique + l'examen des stratégies
                                visuelles + la détermination des critères de la perception visuelle.
                            </p>
                        </div>
                        <div class="ml-3 ml-lg-5 consultations__text__content__paragraph">
                            <h3 class="h4">Basse vision</h3>
                            <p><em>60 minutes</em><br>
                                C'est une déficience visuelle fonctionnelle même après traitements médicaux dont
                                l'acuité
                                visuelle est inférieure à 3/10ème, ou avec un champ visuel résiduel inférieur à 10°. Le
                                bilan permet de faire un état des lieux sur les besoins quotidiens du patient et les
                                capacités visuelles et fonctionnelles.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-4 py-3 text-center consultations__images">
                    <img src="@/assets/img/consultations-bilan.jpg" alt="photo Bilan" class="img-fluid shadow rounded">
                </div>
            </div>
            <div
                class="row d-flex flex-column-reverse flex-lg-row align-items-center consultations__reeducation bg-light">
                <div class="col-12 col-lg-4 py-3 text-center consultations__reeducation__images">
                    <img src="@/assets/img/consultations-reeduc.jpg" alt="photo Bilan" class="img-fluid shadow rounded">
                </div>
                <div class="col-12 col-lg-8 p-1 p-lg-2 consultations__text text-white">
                    <div class="m-1 m-lg-2 p-2 rounded consultations__text__content">
                        <h2>La rééducation Orthoptique</h2>
                        <div class="ml-3 ml-lg-5 consultations__text__content__paragraph">
                            <h3 class="h4">Sensori-Motrice</h3>
                            <p><em>20 minutes environ, 1 à 2 fois par semaine</em><br>
                                Donner de nouveau un équilibre accommodo-convergence endurant et automatique par
                                l'entraînement des amplitudes fusionnelles et des facilités accommodatives, accompagné
                                des
                                lunettes ou lentilles s'il y en a.
                            </p>
                        </div>
                        <div class="ml-3 ml-lg-5 consultations__text__content__paragraph">
                            <h3 class="h4">Neuro-visuelle</h3>
                            <p><em>30 à 40 minutes, 1 fois par semaine</em><br>
                                Donner un outil oculomoteur, sensoriel et fonctionnel adapté permettant un accès normal
                                à la
                                saisie de l'information visuelle et à sa bonne intégration. Ce qui permet de diminuer la
                                fatigabilité et d'accroître le temps d'exécution dans les tâches fonctionnelles liées à
                                l'apprentissage.
                            </p>
                        </div>
                        <div class="ml-3 ml-lg-5 consultations__text__content__paragraph">
                            <h3 class="h4">Basse vision</h3>
                            <p><em>45 à 60 minutes</em><br>
                                Le but est de conserver, quand c'est possible, l'autonomie dans les activités désirées,
                                en
                                travaillant sur les zones de vision encore saines. L'objectif est de ré-orienter et
                                d'utiliser ces dernières
                                dans la prise de fixation et l'organisation du geste, en utilisant des supports adaptés
                                aux capacités visuelles du patient.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row consultations__examens d-flex align-items-center">
                <div class="col-12 col-lg-8 p-1 p-lg-2 consultations__text text-white">
                    <div class="m-1 m-lg-2 p-2 rounded consultations__text__content">
                        <h2>Les examens complémentaires</h2>
                        <div class="ml-3 ml-lg-5 consultations__text__content__paragraph">
                            <h3 class="h4">Renouvellement des lunettes</h3>
                            <p class="mb-1"><em>40 minutes</em>, <a
                                    href="https://www.legifrance.gouv.fr/affichTexte.do?cidTexte=JORFTEXT000041821865&dateTexte&categorieLien=id&fbclid=IwAR34wIVl1bV1GcChL8LPZ1RKWG7TcEkTtuyqLDgsb-MyOm6u8TI_HeOLNUM"
                                    target="_blank">Texte
                                    de loi</a>
                                <br>Avec une ordonnance de lunettes en cours de validité, l'orthoptiste peut faire une
                                vérification et une adaptation si besoin de la correction optique :
                            </p>
                            <ul class="list-unstyled">
                                <li><em>&lt; 16 ans : </em> ordonnance datant de moins d'1 an</li>
                                <li><em>de 16 à 42 ans :</em> ordonnance datant de moins de 5 ans</li>
                                <li><em>&gt; 42 ans :</em> ordonnance datant de moins de 3 ans</li>
                            </ul>
                        </div>
                        <div class="ml-3 ml-lg-5 consultations__text__content__paragraph">
                            <h3 class="h4">Champ visuel automatisé</h3>
                            <p><em>30 minutes</em><br>
                                Détermination des zones de vision et de non vision qui peuvent être impactées par des
                                pathologiques oculaires et palpébrales.
                            </p>
                        </div>
                        <div class="ml-3 ml-lg-5 consultations__text__content__paragraph">
                            <h3 class="h4">Dépistage de la rétinopathie diabétique</h3>
                            <p><em>40 minutes</em><br>
                                4 clichés de la rétine sans dilatation avec analyse à distance par l'ophtalmologiste.
                            </p>
                        </div>

                        <div class="ml-3 ml-lg-5 consultations__text__content__paragraph">
                            <h3 class="h4">Vision des couleurs</h3>
                            <p><em>20 minutes</em><br></p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-4 py-3 text-center consultations__images">
                    <img src="@/assets/img/consultations-exams.jpg" alt="photo Bilan" class="img-fluid shadow rounded">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        mapActions
    } from 'vuex';
    export default {
        name: 'Consultations',
        methods: {
            ...mapActions(['updateActive'])
        },
        mounted() {
            window.scrollTo(0, 0);            
            this.updateActive(this.$route);
            document.title = 'Camille Barès - Les consultations'
        }
    }
</script>

<style lang="scss" scoped>

</style>